import React,{useState} from 'react';
import { homeResourcesContent } from '../../data/homeContent';


export const HomeResourcesSection = () => {
    const [activeSection, setActiveSection] = useState(0);
    
  return (
      <section className='w-11/12 mx-auto'>
          <h1 className='text-[30px] lg:text-[38px] font-[700]'>{homeResourcesContent.title.normal}<span className='text-[#0361FE]'>{homeResourcesContent.title.coloured}</span></h1>
          <p className='font-[400] text-[18px] mt-5'>{homeResourcesContent.text}
          </p>
          <div className='flex flex-wrap justify-center md:justify-evenly 2xl:justify-center lg:mt-12 2xl:space-x-10'>
              {
                  homeResourcesContent.data.map((data, index) => {
                      return (
                          <div className='md:w-[300px] xl:w-[385px] h-[480px] boxShadow p-6 rounded-[12px] mt-6 lg:mt-0 cursor-pointer' key={index} onClick={()=>setActiveSection(index)} onKeyDown={()=>setActiveSection(index)}>
                              <img src={data.img} alt={data.name} className="object-cover w-full"/>
                              <div className='mt-3'>
                                  <h4 className={`text-[22px] font-[700] ${activeSection===index && "text-[#0361FE]"}`}>{data.name}</h4>
                                  <p className='mt-3 text-[14px] font-[400]'>{data.subtext}</p>
                              </div>
                                  {activeSection===index && <img src={homeResourcesContent.lineImage} alt="line" className="my-4"/>}
                          </div>
                      )
                  })
              }
          </div>
    </section>
  )
}
