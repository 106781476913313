import React from 'react';
import { HomeHrmsSection } from './HomeHrmsSection';
import { homeContent } from '../../data/homeContent';
import { HomePillarsSection } from './Pillars';
import { HomeResourcesSection } from './HomeResourcesSection';
import "./home.css";
import { useModal } from '../ContactForm';

export const Home = () => {
  const { modalAction } = useModal();
  return (
      <div className='pb-4'>
          <section className='flex flex-col md:items-center relative overflow-hidden pl-5 md:pl-0'>
              <div className='text-[35px] md:text-[72px] font-[700] flex flex-col md:justify-center md:items-center purple_to_gradient'>
              {/* <div className='text-[35px] md:text-[72px] font-[700] flex flex-col md:justify-center md:items-center text-[#0361FE]'> */}

                  <h1>{homeContent.sectionOne.titleOne}</h1>
                    <h1 >{homeContent.sectionOne.titleTwo}</h1>
              </div>
              <p className='text-[18px] font-[400] my-5 md:my-0'>{homeContent.sectionOne.objectiveLine}</p>
              <span className='circle absolute top-[-650px] hidden md:block overflow-hidden'></span>
              <button className='bg-[#0E5FED] w-[150px] md:w-auto text-white py-[11px] px-[25px] rounded-[48px] my-[20px] md:mt-[80px]' onClick={modalAction}>{homeContent.sectionOne.bookButton}</button>
              
          </section>

          <section className='relative overflow-hidden flex flex-col items-center pb-[40px] md:pb-[120px]'>
              <div className='flex justify-center p-4'>
                <img src={homeContent.sectionOne.bannerImage} alt="DashboardImage" className='rounded-[12px]'/>
                <span className='inverted_circle absolute w-[2360px] h-[1744px] z-[-1] rounded-[50%] top-[10%]'/>
              </div>
              <div className='flex flex-col items-center pl-6 lg:pl-0'>
                  {/* <span className='gradient w-[2360px] h-[1744px] absolute top-[15%] md:top-[30%] z-[-1] rounded-[50%]'></span> */}
                  <span className=' w-[2360px] h-[1744px] absolute top-[15%] md:top-[30%] z-[-1] rounded-[50%]'></span>
                  <p className='text-[38px] font-[700]'>{homeContent.sectionTwo.title.normal} <span className='text-[#0E5FED] block md:inline'>{homeContent.sectionTwo.title.coloured}</span></p>
                  <p className='text-[18px] lg:text-center font-[400] lg:w-[600px] mt-4 leading-[27px]'>{homeContent.sectionTwo.paraLine}</p>
              </div>

            <div className='mt-8 bg-[#0E5FED] h-auto lg:h-[325px] rounded-[39px] py-6 lg:py-0 flex flex-col md:flex-row w-11/12 leading-[29px]'>
                  <div className='flex flex-col items-center justify-center text-center text-white first_block px-6'>
                      <img src={homeContent.sectionTwo.columnData.firstColumn.icon} className="" alt="resize_logo" />
                      <p className='mt-7 px-5 md:px-0'>{homeContent.sectionTwo.columnData.firstColumn.text}</p>
                </div>
                  <div className='flex flex-col items-center justify-center text-center text-white px-6 second_block'>
                      <img src={homeContent.sectionTwo.columnData.secondColumn.icon} alt="objective_logo" />
                      <p className='mt-7 px-5 md:px-0'>{homeContent.sectionTwo.columnData.secondColumn.text}</p>
                </div>
                  <div className='flex flex-col items-center justify-center text-center text-white third_block px-6'>
                      <img src={homeContent.sectionTwo.columnData.thirdColumn.icon} alt="teams_logo" />
                      <p className='mt-7 px-5 md:px-0'>{homeContent.sectionTwo.columnData.thirdColumn.text}</p>
                </div>
                  
            </div>
          </section>

          <HomeHrmsSection />
          <HomePillarsSection />
          <HomeResourcesSection />
      {/* <HRMS />
      <Pillars />
      <Resources /> */}
    </div>
  )
}
