import React from 'react';
import { homePillarsContent } from '../../data/homeContent';


export const HomePillarsSection = () => {

  return (
      <section className='w-11/12 mx-auto my-12 flex flex-wrap justify-center'>
          
          {/* <div className='w-full xl:w-5/12 relative'>
              <div className='absolute gradient z-[-1] h-[100%] w-[100%] rounded-[29px]'></div>
              <div className='py-4'>
                  <div className='px-10 pt-5 mb-3'>
                      <h4 className='text-[30px] lg:text-[38px] text-black font-[700]'>{homePillarsContent.boxOne.title.normal}<span className='text-[#0361FE]'>{homePillarsContent.boxOne.title.coloured}</span></h4>
                  <p className='mt-4 text-[18px] font-[400]'>{homePillarsContent.boxOne.text}</p>
                </div>
                  <img src={homePillarsContent.boxOne.pillarCompaniesLogo} alt="pillar_companies" className='object-cover mx-auto'/>
              </div>
          </div> */}

              <div className='h-auto relative w-10/12 xl:w-auto mb-4 xl:mb-0 lg:mr-4 2xl:mr-10'>
                    {/* <div className='absolute gradient z-[-1] h-[100%] w-[100%] rounded-[29px]'></div> */}
                    <div className='absolute  z-[-1] h-[100%] w-[100%] rounded-[29px]'></div>
                    <div className='p-8'>
                        <h4 className='text-[30px] lg:text-[38px] text-black font-[700]'>{homePillarsContent.boxTwo.title.normal}<span className='text-[#0361FE]'>{homePillarsContent.boxTwo.title.coloured}</span></h4>
                        <p className='mt-4 text-[18px] font-[400] max-w-[480px]'>{homePillarsContent.boxTwo.text}</p>
                    </div>
                
              </div>
                <div className='h-auto relative w-10/12 xl:w-auto'>
                    {/* <div className='absolute gradient z-[-1] h-[100%] w-[100%] rounded-[29px]'></div> */}
                    <div className='absolute  z-[-1] h-[100%] w-[100%] rounded-[29px]'></div>
                    <div className='p-8 flex flex-col md:flex-row justify-evenly'>
                      {
                          homePillarsContent.boxThree.map((data, index) => {
                              return (
                                  <div key={index} className="flex flex-col items-center py-3 md:py-0 mt-4 md:mt-0">
                                      <img src={data.icon} alt="solution_icons" className='h-[62px] w-[62px]'/>
                                      <p className='md:w-[180px] mt-5 font-[500] text-center'>{data.text}</p>
                                </div>
                            )
                        })  
                        }
                    </div>
                
              </div>
            {/* </div> */}
                  
    </section>
  )
}
