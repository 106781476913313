import React from 'react';
import { homeHRMSContent } from '../../data/homeContent';
import "./home.css";

export const HomeHrmsSection = () => {

  return (
      <section className='hrms_section pl-6 lg:pl-14 py-10'>
          <h1 className='text-white text-[35px] lg:text-[50px] font-[700]'>{homeHRMSContent.title.normal}<span className='text-[#0361FE] block lg:inline'>{homeHRMSContent.title.coloured}</span></h1>
          <div className='flex flex-wrap mt-6'>
              <div className='lg:w-1/3 pr-8'>
                  {
                      homeHRMSContent.data.map((data, index) => {
                          return (
                              <div className={`${index!==homeHRMSContent.data.length-1 && "dotted_bottom"} h-[150px]`} key={index}>
                                  <div className='flex items-start'>
                                    <img src={data.img} alt={data.name} className="mr-8 mt-5"/>
                                  <div className='pt-2'>
                                      <h1 className='text-[25px] lg:text-[30px] font-[700] text-white'>{data.name}</h1>
                                      <p className='text-[#ffffff75] mt-2'>{data.subtext}</p>
                                  </div>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
              <img src={homeHRMSContent.hrmsImage} alt="hrms_photo" className='lg:w-2/3 rounded-l-[12px]'/>
          </div>
    </section>
  )
}
